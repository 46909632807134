<template>
  <div class="page-members-detail">
    <Loading v-if="loading" :count="6"></Loading>
    <el-descriptions v-else :column="1" :labelStyle="{ width: '140px' }">
      <el-descriptions-item :label="$t('members.role')">
        <span v-if="detail.role === 1">{{ $t('members.role-leader') }}</span>
        <span v-if="detail.role === 2">{{ $t('members.role-member') }}</span>
        <span v-if="detail.role === 3">{{ $t('members.role-instructor') }}</span>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('members.name')">
        {{ detail.name }}
      </el-descriptions-item>
      <el-descriptions-item :label="$t('members.idNum')">
        {{ detail.idNum }}
      </el-descriptions-item>
      <el-descriptions-item :label="$t('members.phone')">
        {{ detail.phoneNum }}
      </el-descriptions-item>
      <el-descriptions-item :label="$t('members.address')">
        {{ detail.address }}
      </el-descriptions-item>
      <el-descriptions-item :label="$t('members.unit')">
        {{ detail.unit }}
      </el-descriptions-item>
      <!-- <el-descriptions-item label="开户行">
        {{ detail.bank }}
      </el-descriptions-item>
      <el-descriptions-item label="银行卡">
        {{ detail.bankCard }}
      </el-descriptions-item> -->
    </el-descriptions>
  </div>
</template>

<script>
import Loading from '@/components/loading'

export default {
  components: {
    Loading
  },
  data() {
    return {
      loading: true,
      id: this.$route.params.id,
      detail: null
    }
  },
  mounted() {
    this.fetchDetail()
  },
  methods: {
    async fetchDetail() {
      this.loading = true
      const [err, res] = await this.$serve.jsTeamMember.info({
        queryId: this.id
      })
      this.loading = false
      if (err) return
      this.detail = res
    }
  }
}
</script>

<style lang="scss" scoped>
.page-members-detail {
  ::v-deep {
    .el-descriptions-item__container {
      min-height: 40px;
      line-height: 40px;
    }
    .el-descriptions-item__label {
      width: 120px;
      text-align: right;
    }
  }
}
</style>
